<template>
  <div class="web margin100">
    <div class="swiper" v-if="banner">
      <el-carousel height="450px" arrow="never">
        <el-carousel-item
          v-for="(item, index) in bannerList"
          :key="index"
        >
          <img :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content">
      <div class="box_title">
        <div class="left">
          <span class="font28 color33">新闻动态</span>
          <span class="font14 color66">News dynamic</span>
        </div>
        <div class="right font16 color99" @click="tomore">
          <span>更多</span>
          <span style="margin-left: 5px" class="el-icon-d-arrow-right"></span>
        </div>
      </div>
      <!-- <el-divider style="margin-bottom: 0"></el-divider> -->

      <div class="list margin100">
        <div class="swiper_box" v-if="articleList1.length != 0">
          <div class="swiper_item">
            <div class="swiper-container swiper-container1">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide swiper-no-swiping"
                  v-for="item in articleList1"
                  :key="item.id"
                >
                  <img
                    :src="
                      item.picUrl
                        ? item.picUrl
                        : require('../../assets/no_pic.png')
                    "
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="swiper_item_2">
            <div class="swiper-container swiper-container2">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide color33"
                  v-for="item in articleList1"
                  :key="item.id"
                  @click="toDetails(item.id)"
                >
                  <p class="title">{{ item.title }}</p>
                  <p class="inner_text">
                    {{ item.subtitle }}
                  </p>
                  <div class="font16 time">
                    <span>{{ item.createTime }}</span>
                  </div>
                </div>
              </div>
              <!-- 如果需要分页器 -->
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
        <div
          v-for="item in articleList2"
          :key="item.id"
          @click="toDetails(item.id)"
        >
          <div class="list_item">
            <div class="left">
              <img
                :src="
                  item.picUrl ? item.picUrl : require('../../assets/no_pic.png')
                "
                alt=""
              />
            </div>
            <div class="right">
              <h2>{{ item.title }}</h2>
              <p class="inner_text">
                {{ item.subtitle }}
              </p>
              <div class="font16">
                <span>{{ item.createTime }}</span>
              </div>
            </div>
            <div class="right_icon">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="my_line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import list from "@/components/list.vue";

import {bannerList, getHomeArticleList} from "@/util/api";
export default {
  components: {
    list,
  },
  data() {
    return {
      banner: {},
      articleList1: [],
      articleList2: [],
      bannerList:[]
    };
  },
  watch: {
    //监听轮播数据变化后再创建实例
    articleList1() {
      this.$nextTick(() => {
        let swiper1 = new Swiper(".swiper-container1", {
          loop: true,
          autoplay: 3000,
          noSwiping: true,
          // 如果需要分页器
          preventsDefault: false, //干掉默认阻止的事件
          observer: true, //初始化子元素
          observerParents: true, //初始化父元素
          // onSlideChangeStart: function (swiper) {
          //   swiper2.slideTo(swiper.activeIndex, 1000, false);
          // },
        });
        let swiper2 = new Swiper(".swiper-container2", {
          loop: true,
          // 如果需要分页器
          autoplay: 3000,
          // 如果需要分页器
          pagination: ".swiper-pagination",
          preventsDefault: false, //干掉默认阻止的事件
          observer: true, //初始化子元素
          observerParents: true, //初始化父元素
          onSlideChangeStart: function (swiper) {
            swiper1.slideTo(swiper.activeIndex, 200, false);
          },
        });
      });
    },
  },
  mounted() {
    this.getBanner();
    this.getArticle();
  },
  methods: {
    getBanner() {
      bannerList({type: 1, domainUrl: window.location.hostname})
        .then((res) => {
          this.banner = res.data && res.data.length != 0 ? res.data[0] : null;
          this.bannerList = this.banner.picUrl.split(';')
        })
        .catch(() => {});
    },
    getArticle() {
      getHomeArticleList({
        domainUrl: window.location.hostname,
      }).then((res) => {
        if (res.data.length >= 2) {
          this.articleList1 = res.data.slice(0, 2);
          this.articleList2 = res.data.slice(2);
        } else {
          this.articleList1 = res.data;
        }
      });
    },
    tomore() {
      this.$router.push("/newsCenter");
    },
    toDetails(id) {
      this.$router.push({
        path: "/newsCenter/newsDetails",
        query: {
          articleId: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-divider--horizontal {
  margin-bottom: 0;
}
.swiper {
  width: 100%;
  height: 450px;
  background-color: #f5f5f5;
}
.el-carousel {
  width: 100%;
  margin: 0px auto;
  img {
    width: 100%;
    height: 100%;
  }
}
.content {
  width: 1200px;
  margin: 40px auto;
  min-height: 200px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  .box_title {
    padding: 40px 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .right {
      cursor: pointer;
    }
  }
}
.list_item {
  display: flex;
  align-items: center;
  padding: 10px 40px;
  position: relative;
  cursor: pointer;
  .left {
    width: 160px;
    height: 96px;
    background-color: skyblue;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    margin-left: 40px;
    flex: 1;
    h2 {
      font-size: 26px;
      color: #333;
    }
    div {
      color: #707070;
      margin-top: 15px;
      i {
        margin-right: 10px;
      }
    }
    .inner_text {
      margin-top: 10px;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      width: 90%;
    }
  }
  .right_icon {
    position: absolute;
    right: 40px;
    font-size: 20px;
  }
}
.my_line {
  border-bottom: 1px solid #cccccc;
  width: 90%;
  margin: 0 auto;
  padding: 10px 0 0;
  margin-bottom: 10px;
}
.swiper_box {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.swiper_item,
.swiper_item_2 {
  width: 600px;
  height: 300px;
  margin-left: 40px;

  .swiper-container,
  .swiper-wrapper,
  .swiper-slide {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    background-color: skyblue;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.swiper_item_2 {
  width: 500px;
  margin-right: 40px;
  margin-left: 0;
  .swiper-slide {
    background-color: #f8f8f8;
    position: relative;
    .title {
      width: 440px;
      margin: 40px auto 20px;
      text-align: left;
      font-size: 26px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: bold;
    }
    .inner_text {
      width: 440px;
      margin: 0 auto;
      font-size: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
    .time {
      position: absolute;
      margin-top: 20px;
      color: #707070;
      right: 20px;
      bottom: 20px;
      span {
        margin-left: 5px;
      }
    }
  }
}
</style>
